.map-container {
  flex: 1;
}

.contacts-container {
  display: flex;
  background: #d3cece;
  position: relative;
  margin-top: 100px;
}

.info-container {
  /* display: flex; */
  height: auto !important;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.mapContainer {
  width: 50%;
}

.contacts-title {
  font-weight: bold;
}

.contacts-line {
  width: 100%;
  max-width: 500px;
}

.contacts-content {
  font-weight: bold;
  margin-right: 60px;
  width: 100%;
}

@media (max-width: 767px) {
  .contacts-container {
    flex-direction: column;
  }

  .contacts-content {
    margin-right: 0;
  }

  .info-container {
    height: 30vh;
  }
}

.cards {
  margin: auto;
  background: #fefefe;
  border-radius: 5px;
  /* overflow: hidden; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 3px 5px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

#container-contacts{
  border-radius: 5px;
  /* overflow: hidden; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 3px 5px 20px rgba(0, 0, 0, 0.2);
}

.circle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px; /* Adjust the width to the desired size of the circle */
  height: 45px; /* Adjust the height to the desired size of the circle */
  border-radius: 50%; /* This makes the container a circle by setting the border radius to half of its width/height */
  border: 2px solid #3c4754; /* The border style and color */
  background-color: #4a5665;
}

@media (max-width: 768px) {
  #contact-container {
    height: auto !important;
  }

}



#volebny_program_id:hover{
  transition: ease all 0.3s;
  opacity: 0.5;
}