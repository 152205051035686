body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

a,
a:hover {
  text-decoration: none !important;
}

#footer_list li a{
  color: #9d9d9d !important;
  font-size: 15px;
}
#footer_list .nav-item{
  margin-left: 12px !important;
  margin-right: 12px !important;
}
#footer_list li a:hover{
  /* color: #5AC7D4 !important; */
  opacity: 0.4;
}

.content {
  height: 70vh;
}

.footer-39201 {
  padding: 0;
  color: #9d9d9d;
  background-color: #111a22;
  /* margin-top: 60px; */
}

.footer-39201 .subscribe {
  position: relative;
}

.footer-39201 .subscribe .form-control {
  background-color: #FDFDFD;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 30px;
  height: 50px;
  padding-left: 30px;
  padding-right: 130px;
  border: none;
  color: #000;
}

.footer-39201 .subscribe .form-control::-webkit-input-placeholder {
  color: #b3b3b3;
  font-style: italic;
  font-size: 14px;
}

.footer-39201 .subscribe .form-control::-moz-placeholder {
  color: #b3b3b3;
  font-style: italic;
  font-size: 14px;
}

.footer-39201 .subscribe .form-control:-ms-input-placeholder {
  color: #b3b3b3;
  font-style: italic;
  font-size: 14px;
}

.footer-39201 .subscribe .form-control:-moz-placeholder {
  color: #b3b3b3;
  font-style: italic;
  font-size: 14px;
}

.footer-39201 .subscribe .btn-submit {
  background: #614ad3;
  height: 40px;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 5px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.footer-39201 h3 {
  font-size: 16px;
  font-weight: 900;
  color: #000;
  margin-bottom: 20px;
}

.footer-39201 .nav-links li {
  display: block;
  margin-bottom: 10px;
}

.footer-39201 .nav-links li a {
  color: #9d9d9d;
}

.footer-39201 .nav-links li a:hover {
  color: #614ad3;
}

.footer-39201 .social li {
  display: inline-block;
}

.footer-39201 .social li a {
  display: inline-block;
  padding: 10px;
  color: #9d9d9d;
}

.footer-39201 .social li a:hover {
  opacity: 0.4;
}

.social-icon {
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .social-icon {
    font-size: 20px;
  }
}

#inpossible-header-id{
  margin: 0 0 15px 0 !important;
}

#header-main-id-program{
  margin: 0 !important;
  /* padding-bottom: 2rem !important; */
}