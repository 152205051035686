.about-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background: linear-gradient(180deg, #ffffff 0%, #89e3f3 100%);
}

.about-video-container {
  width: 100%;
  max-width: 800px;
  margin-bottom: 120px;
}

.about-video {
  width: 100%;
  height: auto;
}

.video-container {
  position: relative;
  width: 100%;
}

.about-video {
  width: 100%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play-button::after {
  content: "";
  border-style: solid;
  border-width: 12px 0 12px 20px;
  border-color: transparent transparent transparent white;
}

.btn-container {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}

.pdf-button {
  background-color: #15a0bb;
  color: white;
  font-weight: bold;
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin: 10px 5px;
}

.pdf-button:hover {
  background-color: #014b57;
}

.about-video-container {
  width: 630px;
  height: 630px;
  /* border-radius: 32%; */
  margin-top: 80px;
  overflow: hidden;
}

.about-video-container video {
  width: 100%;
  height: 100%;
  transform: scale(1.8);
  transform-origin: center;
}

#main {
  background-color: #0c141b !important;
  /* height: 100% !important; */
}

#main1 {
  height: 100vh !important;
}

#div-h2-id {
  text-transform: uppercase;
  color: #252525;
  font-size: 50px;
  margin-bottom: 20px;
  font-weight: 600;
}

@media (max-width: 767px) {
  #div-h2-id {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
  }

  .container{
    text-align: left !important;
  }

  #strana {
    font-size: 16px;
  }

  .about-section-text {
    font-size: 14px;
  }

  .pdf-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .about-video-container {
    margin-top: 90px;
    height: 300px;
    width: 300px;
  }

  .about-container {
    margin: 0 auto !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .hands-img {
    margin: 0 auto !important;
    width: 400px !important;
    height: 300px !important;
    object-fit: cover !important;
  }

  .about-content {
 
    width: 300px !important;
    margin: 0 auto !important;
  }
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hands-img {
  width: 100%;
  /* height: 550px; */
  border-radius: 40%;
  object-fit: cover !important;
}
