p{
    color: rgb(109, 109, 109);
}

li{
    color: rgb(109, 109, 109);
}

h3{
    color: rgb(85, 185, 203);
}


@media (max-width: 767px) {
    #text-id-1 h2{
        margin: 0 !important;
        padding-bottom: 15px !important;
    }
}