/* #header-main-id-program{
    color: #252525 !important;
} */

@media (max-width: 767px) {
    #text-id-1 {
        text-align: left !important;
    }

    #text-id-2 {
        text-align: left !important;
    }

    #text-id-3 {
        text-align: left !important;
    }

    #text-id-4 {
        text-align: left !important;
    }

    #text-id-5 {
        text-align: left !important;
    }
}

p {
    font-size: 19px !important;
}

.heading-hover {
    transition: ease opacity 0.3s;
    /* Define transition for both states */
    opacity: 1;
    /* Set initial opacity */
}

.heading-hover:hover {
    transition: ease all 0.3s;
    opacity: 0.3;
}

@media print {
    body {
        visibility: hidden;
    }

    .info-background {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0;
    }
}

#pdf_download:hover{
    transition: ease all 0.3s;
    opacity: 0.5;
  }